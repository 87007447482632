import React, { useState, useEffect } from "react";
import Axios from "axios";
import Styles from "../../Styles/scoreboard.module.scss";
import Headlogo from "../../Assets/Leaderboard/headlogo.webp";
import Video from "../Counter/Video";


const BlindTennisscoreboard = () => {
  const [data, setData] = useState([]);

  const Api = `${process.env.REACT_APP_API_URL}/scoreboard/game/675288138fe7ab7a55048131?top=1`;

  const getdata = async () => {
    try {
      let response = await Axios.get(Api);

      setData(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };
  

  useEffect(() => {
    getdata();
    const pollInterval = setInterval(() => {
      getdata();
    }, 5000);

    return () => {
      clearInterval(pollInterval);
    };
  }, []);

  const fielddata = Array.from({ length: 10 }, (_, index) => {
    return (
      data[index] || {
        uniqueCode: `placeholder-${index}`, // Corrected string interpolation
        user: { name: " " },
        score: " ",
      }
    );
  });
  

  // if (!data) {
  //   return <Loading content={"Loading..."} />;
  // }
  return (
    <div className={Styles.leadermaincontainer}>
      <div className={Styles.leaderfixed}>
        <div className={Styles.leaderimgmain}>
          <img src={Headlogo} alt="headimg" draggable="false" />
        </div>
        <div className={Styles.leaderlogocontainer}>
          <h1>BLACKOUT TENNIS</h1>
        </div>

        <div className={Styles.leadersubheadingcontainer}>
          <div>
            <p>NAME</p>
          </div>

          <div>
            <p>SCORE</p>
          </div>
        </div>

        <div className={Styles.mainscoreboardcontainer}>
          { fielddata.map((item, index) => (
            <div key={item.uniqueCode} className={Styles.mainscoreboardrow}>
              <div className={Styles.containerone}>{index + 1}</div>
              <div className={Styles.separator}></div>
              <div className={Styles.containertwo}>{item.user.name}</div>
              <div className={Styles.containerthree}>{item.score}</div>
            </div>
          ))}

         
        </div>
      </div>
     
      <div className={Styles.countvideo}>
      <Video  />
      </div>
    </div>
  );
};

export default BlindTennisscoreboard;
