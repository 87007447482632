import React from "react";

const Loading = ({ content }) => {
  return (
    <div className="loadingmaincontainer">
      <h1 className="loadinghead">{content}</h1>
    </div>
  );
};

export default Loading;
