import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "./axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./Components/Loading";
//All Routes
import AdminLogin from "./Components/Admin/Login";
import ExportData from "./Components/Export/Export";
import Vrtennisscoreboard from "./Components/VrTennis/Vtllandscape";
import VrtennisscoreboardP from "./Components/VrTennis/Vtl";
import Vrtennisscoreboarddata from "./Components/VrTennis/Vtldata";
import BlindTennisscoreboard from "./Components/BlindTennis/Btl";
import BlindTennisscoreboarddata from "./Components/BlindTennis/Btldata";
import Counter from "./Components/Counter/Counter";
import UserRegistration from "./Components/Registration/Reg";
import UnprotectedRoute from "./UnProtectedRoute";
import PrivateRoute from "./PrivateRoute";

function App() {
 

  return (
    <Router>
      <div className="mainhomecontainer">
        <Routes>
          <Route path="" element={<UnprotectedRoute />}>
            <Route path="/" element={<UserRegistration />} />
            <Route path="/btl" element={<BlindTennisscoreboard />} />
            <Route path="/vtl" element={<Vrtennisscoreboard />} />
            <Route path="/vtlp" element={<VrtennisscoreboardP />} />
            <Route path="/login" element={<AdminLogin />} />
          </Route>
          <Route path="" element={<PrivateRoute />}>
            <Route path="/exportdata" element={<ExportData />} />
            <Route path="/count" element={<Counter />} />
            <Route path="/btldata" element={<BlindTennisscoreboarddata />} />
            <Route path="/vtldata" element={<Vrtennisscoreboarddata />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
