import React, { useRef, useEffect, useState } from "react";
import Styles from "../../Styles/reg.module.scss";
import Swal from "sweetalert2";
import axios from "axios";
import Headlogo from "../../Assets/Logo/headlogo.webp";

function Registration() {
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [BtnSelected, setBtnSelected] = useState(false);
  const [ButtonState, setButtonState] = useState(false);

  const form = useRef();
  const isEnglish = (text) =>
    /^[A-Za-z0-9@.,;!#$%&'()*+\/=?^_`{|}~\s-]*$/.test(text);

  const Submithandleselector = () => {
    setBtnSelected(true);
  };

  const validateEnglishInput = (e, setState) => {
    const value = e.target.value;
    if (!isEnglish(value)) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        background:"#00332a",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
        html: '<div style="color: white;"> Please Type Only in English</div>',
        timer: 2000,
      });
    } else {
      setState(value);
    }
  };

  const handleSubmit = (e) => {
    setTimeout(() => {
      setBtnSelected(false);
    }, 1000);

    e.preventDefault();
    if (!name.trim() || !email.trim() || !phone.trim()) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        background:"#00332a",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
        html: `
         
            <div>Please Fill All the Fields</div>
          
        `,
        timer: 4000,
      });

      return;
    }

    if (!isEnglish(name) || !isEnglish(email)) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        background:"#00332a",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
        html: '<div style="color: white;"> Please Fill in English Only</div>',
        timer: 4000,
      });
    } else if (name.trim() === "") {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        background:"#00332a",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
        html: '<div style="color: white;"> Name Cannot Be Empty</div>',
        timer: 4000,
      });
    } else if (email.length < 5) {
      Swal.fire({
        title: "Oops...",
        background:"#00332a",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
        html: '<div style="color: white;">Email Should Be at Least 5 Characters Long</div>',
        timer: 4000,
      });
    } else if (email.split("").filter((x) => x === "@").length !== 1) {
      Swal.fire({
        title: "Oops...",
        background:"#00332a",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
        html: '<div style="color: white;">Email Should Contain  @</div>',
        timer: 4000,
      });
    } else if (email.indexOf(".") === -1) {
      Swal.fire({
        title: "Oops...",
        background:"#00332a",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
        html: '<div style="color: white;"> Email Should Contain at Least One Dot</div>',
        timer: 4000,
      });
    } else {
      setButtonState(true);
      Submithandleselector(true);
      Swal.fire({
        title: "",
        showCloseButton: false,
        background:"#00332a",
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
        html: `
          <div style="text-align: center;">
            <div class="loader"></div>
            <div style="color: white;">Submitting Your Data...</div>
          </div>
        `,
        timer: 4000, // Optional timer or you can remove this line
      });
      const API = process.env.REACT_APP_API_URL;

      axios
        .post(`${API}/user`, { name, email, phone })

        .then((response) => {
          setButtonState(false);
          SetName("");
          SetEmail("");
          SetPhone("");
          Submithandleselector(false);

          if (!response.data.err) {
            Swal.fire({
              width: "600px",
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
                closeButton: "custom-close-btn",
              },
              backdrop: `rgba(0, 0, 0, 0.9)`,
              html: `
                <div style="
                  display: flex; 
                  flex-direction: column; 
                  align-items: center; 
                  justify-content: center; 
                  background-image: url('https://images.ortmoragency.com/ksa/message-bg-with-txt.webp'); 
                  background-size: contain; 
                  background-repeat: no-repeat; 
                  background-position: center; 
                  width: 90%; 
                  max-width: 700px; 
                  height: 80vh; 
                  max-height: 700px; 
                  color: white; 
                  text-align: center; 
                  box-shadow: 0; 
                  border-radius: 0;
                  padding: 20px; 
                  border: none; 
                  outline: none;
                  overflow: hidden;
                ">
                  <h2 style="color: #cca665; font-weight: bold; font-size: 2rem; margin-top: 7em;">
                    ${response.data.results.code}
                  </h2>
                </div>
                <div id="custom-close" style="position: absolute; top: 10px; right: 10px; cursor: pointer;">
                  <img src="https://images.ortmoragency.com/ksa/close.webp" alt="Close" style="width: 50px; height: 50px;" />
                </div>
              `,
            });
            
            // Add a click event for the custom close button
            document.getElementById('custom-close').addEventListener('click', () => {
              Swal.close();
            });
          } else {
            setButtonState(false);
            Submithandleselector(false);
            Swal.fire({
              title: "Oops...",
              background:"#00332a",
              icon: "error",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
              html: '<div style="color: white;"> Registartion Failed</div>',
              showConfirmButton: false,
              timer: 1500,
            });
            console.log("Some error occurred");
          }
        })
        .catch((err) => {
          setButtonState(false);
          Submithandleselector(false);
          let errorMessage = "Something Went Wrong!";
          if (err.response) {
            if (err.response.data && err.response.data.message) {
              if (err.response.data.message === "Email already registered") {
                errorMessage = "Email already registered";
              } else {
                errorMessage = err.response.data.message;
              }
            } else {
              errorMessage = `Error: ${err.response.status}`;
            }
          } else if (err.request) {
            errorMessage =
              "Oops! We couldn't reach the server. Please check your internet connection and try again.";
            console.log(err.request);
          } else {
            errorMessage = err.message;
          }
          Swal.fire({
            title: "Oops...",
            background:"#00332a",
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              popup: "custom-swal",
              htmlContainer: "custom-html",
              title: "swal2-title",
            },
            backdrop: `rgba(0,0,0,0.9) left top no-repeat`,
            html: `<div style="color: white;">${errorMessage}</div>`,
            showConfirmButton: false,
            timer: 4000,
          });
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={Styles.contactcolorbg}>
      <div className={Styles.contactsection}>
        <div className={Styles.contactimgmain}>
          <img src={Headlogo} alt="headimg" />
        </div>
        <form
          ref={form}
          className={Styles.contactform}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className={Styles.contactforminputmain}>
            <input
              className={Styles.inputNameEmail}
              type="text"
              id="user_name"
              placeholder="Name"
              value={name}
              onChange={(e) => validateEnglishInput(e, SetName)}
              maxLength={50}
              minLength={1}
              autoComplete="off"
              name="user_name"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <input
              className={Styles.inputNameEmail}
              type="number"
              id="user_phone"
              placeholder="Mobile Number"
              value={phone}
              onChange={(e) => validateEnglishInput(e, SetPhone)}
              maxLength={30}
              minLength={1}
              autoComplete="off"
              name="user_phone"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <input
              className={Styles.inputNameEmail}
              type="email"
              id="user_email"
              placeholder="Email"
              value={email}
              autoComplete="off"
              onChange={(e) => validateEnglishInput(e, SetEmail)}
              name="user_email"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <div className={Styles.contactsubmitmain}>
              <button
                disabled={ButtonState}
                onClick={Submithandleselector}
                className={
                  BtnSelected ? Styles.submitbtnselected : Styles.submitbtn
                }
                type="submit"
              ></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Registration;
