import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import Styles from "../../Styles/export.module.scss";
import headLogo from "../../Assets/Logo/headlogo.webp";




const Counter = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Retrieve the token from local storage
    const yourToken = localStorage.getItem('adminToken');
    
    if (yourToken) {
        // Create a socket.io connection with the token
        const newSocket = io(
          `${process.env.REACT_APP_API_URL}/timer-controller`,
        
          {
            reconnectionDelayMax: 10000,
        
            auth: {
              token: `Bearer ${yourToken}`,
          },
          }
        );
        // Set the socket variable in state
        setSocket(newSocket);
    } else {
        console.error('Token not found in local storage');
    }
  }, []);

  const handleStart = () => {
    console.log("Starting video...");
    socket.emit("timer","START"); // Emit start event
  };

  const handleStop = () => {
    console.log("Stopping video...");
    socket.emit("timer", "STOP"); // Emit stop event
  };

  return (
    <div className={Styles.exportdatahomecontainer}>
      <div className={Styles.exportlogocontainer}>
        <img src={headLogo} alt="Logo" />
      </div>
      <div className={Styles.exportdatamain}>
        <div>
          <button className={Styles.exportbtn} onClick={handleStart}>
            Start
          </button>
        </div>
        <div>
          <button className={Styles.exportbtn} onClick={handleStop}>
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default Counter;
