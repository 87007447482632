import React, { useRef, useEffect, useState } from "react";
import Styles from "../../Styles/reg.module.scss";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Headlogo from "../../Assets/Logo/headlogo.webp";

function Login() {
  const [email, setEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [ButtonState, setButtonState] = useState(false);
  const dispatch = useDispatch();

  const form = useRef();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        background:"#00332a",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;">User Name Cannot Be Empty</div>',
        timer: 4000,
      });
      return;
    } else if (password.length < 5) {
      Swal.fire({
        title: "Oops...",
        background:"#00332a",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;">Password Should Be at Least 5 Characters Long</div>',
        timer: 4000,
      });
      return;
    }

    setButtonState(true);
    Swal.fire({
      title: "",
      showCloseButton: false,
      background:"#00332a",
      showConfirmButton: false,
      customClass: {
        popup: "custom-swal",
        htmlContainer: "custom-html",
        title: "swal2-title",
      },
      backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
      html: `
        <div style="text-align: center;">
          <div class="loader"></div>
          <div style="color: white;">Login...</div>
        </div>
      `,
      timer: 4000, // Optional timer
    });

    const API = process.env.REACT_APP_API_URL;

    const login = async () => {
      try {
        const response = await axios.patch(`${API}/admin/login`, {
          email,
          password,
        });

        if (!response.data.err) {
          localStorage.setItem("adminToken", response.data.results.token);
          await Swal.fire({
            title: "Login Successful!",
            background:"#00332a",
            icon: "success",
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              popup: "custom-swal",
              htmlContainer: "custom-html",
              title: "swal2-title",
            },
            backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
            timer: 1500,
          });
          // navigate("/dashboard");
        } else {
          Swal.fire({
            title: "Oops...",
            background:"#00332a",
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              popup: "custom-swal",
              htmlContainer: "custom-html",
              title: "swal2-title",
            },
            backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
            html: '<div style="color: white;">Login Failed</div>',
            timer: 1500,
          });
          console.log("Some error occurred");
        }
      } catch (err) {
        let errorMessage = "Something Went Wrong!";
        if (err.response) {
          errorMessage =
            err.response.data?.message || `Error: ${err.response.status}`;
        } else if (err.request) {
          errorMessage =
            "Oops! We couldn't reach the server. Please check your internet connection and try again.";
          console.log(err.request);
        } else {
          errorMessage =
            err.response.data?.message || `Error: ${err.response.status}`;
        }
        Swal.fire({
          title: "Oops...",
          background:"#00332a",
          icon: "error",
          showCloseButton: true,
          showConfirmButton: false,
          customClass: {
            popup: "custom-swal",
            htmlContainer: "custom-html",
            title: "swal2-title",
          },
          backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
          html: `<div style="color: white;">${errorMessage}</div>`,
          timer: 4000,
        });
      } finally {
        setButtonState(false);
        setEmail("");
        SetPassword(""); 
      }
    };

    await login();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={Styles.contactcolorbg}>
      <div className={Styles.contactsection}>
        <div className={Styles.contactimgmain}>
          <img src={Headlogo} alt="headimg" />
        </div>

        {/* linesection */}
        <div className={Styles.contactviewcontainerline}>
          <img src="" />
        </div>
        {/* ends */}

        <form
          ref={form}
          className={Styles.contactform}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className={Styles.contactforminputmain}>
            <div className={Styles.contactopeninghead}>
              <h1>Administration</h1>
            </div>
          </div>

          <div className={Styles.contactforminputmain}>
            <span className={Styles.inputslide} />
            <input
              className={Styles.inputNameEmail}
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={30}
              minLength={1}
              autoComplete="off"
              name="email"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <span className={Styles.inputslide} />
            <input
              className={Styles.inputNameEmail}
              type="password"
              id="user_password"
              placeholder="Password"
              value={password}
              autoComplete="off"
              onChange={(e) => SetPassword(e.target.value)}
              maxLength={30}
              minLength={1}
              name="user_password"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <button
              disabled={ButtonState}
              className={Styles.submitbtn}
              type="submit"
            ></button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
