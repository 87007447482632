import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Styles from "../../Styles/scoreboard.module.scss";
import videoUrl from "../../Assets/Video/count.mp4";

// Set up socket connection

const Video = () => {
  const [socket, setSocket] = useState(null);
  const [buttonText, setButtonText] = useState("Start Countdown");

  // useEffect(()=>{
  //   const newSocket = io(
  //     `${process.env.REACT_APP_API_URL}/timer-viewer`,
  //     {
  //       reconnectionDelayMax: 10000,

  //     }
  //   );
  //   setSocket(newSocket);

  // },[])
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;
    const socket = io(`${process.env.REACT_APP_API_URL}/timer-viewer`, {
      reconnectionDelayMax: 10000,
    });
    // Listen for start event
    socket.on("timer", (status) => {
      console.log("Received start event, status:", status);
      if (status === "START") {
        setButtonText("Restart"); 
        videoElement.play().catch((err) => console.error("Play error:", err));
      }
    });

    // Listen for stop event
    socket.on("timer", (status) => {
      console.log("Received stop event, status:", status);
      if (status === "STOP") {
        videoElement.pause();
        setButtonText("Start Countdown")
        videoElement.currentTime = 0; // Reset video to the start
      }
    });

    // Clean up socket listeners on component unmount
    return () => {
      socket.off("timer");
    };
  }, []);

  const Videostart = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; 
      videoRef.current.play(); 
      setButtonText("Restart"); 
    }
  };

  const handleKeyDown = (event) => {
    if (event.code === "Space" || event.code === "Enter") {
      event.preventDefault(); // Prevent default scrolling for Space
      Videostart();
    }
  };


  const handlePauseOrEnd = () => setButtonText("Start Countdown");

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className={Styles.leadermaincontainer}>
      <div className={Styles.btnvideocontainer}>
        <video
          className={Styles.videocontainer}
          ref={videoRef}
          width="100%"
          src={videoUrl}
          muted
          playsInline
          controlsList="nodownload"
          onPause={handlePauseOrEnd}
          onEnded={handlePauseOrEnd}
        />
        <button onClick={Videostart}>{buttonText}</button>
      </div>
    </div>
  );
};

export default Video;
