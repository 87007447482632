import React, { useState, useEffect } from "react";
import Axios from "axios";
import Styles from "../../Styles/scoreboardland.module.scss";
import Headlogo from "../../Assets/Leaderboard/landscape/logo.webp";

const Vrtennisscoreboard = () => {
  const [data, setData] = useState([]);

  const Api = `${process.env.REACT_APP_API_URL}/scoreboard/game/675288058fe7ab7a5504812f?top=1`;

  const getdata = async () => {
    try {
      let response = await Axios.get(Api);

      setData(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getdata();
    const pollInterval = setInterval(() => {
      getdata();
    }, 5000);

    return () => {
      clearInterval(pollInterval);
    };
  }, []);

  const fielddata = Array.from({ length: 8 }, (_, index) => {
    return (
      data[index] || {
        uniqueCode: `placeholder-${index}`, // Corrected string interpolation
        user: { name: " " },
        score: " ",
      }
    );
  });

  return (
    <div className={Styles.leadermaincontainerland}>
      <div className={Styles.leaderfixedland}>
        <div className={Styles.leaderimgmainland}>
          <img src={Headlogo} alt="headimg" draggable="false" />
        </div>
        <div className={Styles.leaderlogocontainerland}>
          <h1>VR TENNIS</h1>
        </div>

        <div className={Styles.leadersubheadingcontainerland}>
          <div>
            <p>NAME</p>
          </div>

          <div>
            <p>SCORE</p>
          </div>
        </div>

        <div className={Styles.mainscoreboardcontainerland}>
          {fielddata.map((item, index) => (
            <div key={item.uniqueCode} className={Styles.mainscoreboardrowland}>
              <div className={Styles.containeroneland}>{index + 1}</div>
              <div className={Styles.separatorland}></div>
              <div className={Styles.containertwoland}>{item.user.name}</div>
              <div className={Styles.containerthreeland}>{item.score}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Vrtennisscoreboard;
