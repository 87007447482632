import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Styles from "../../Styles/export.module.scss";
import headLogo from "../../Assets/Logo/headlogo.webp";

const MySwal = withReactContent(Swal);

function ExportData() {
  const yourToken = localStorage.getItem("adminToken");

  const handleExportClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/scoreboard/export?format=json`,
        {
          headers: {
            Authorization: `Bearer ${yourToken}`,
          },
        }
      );

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      const jsonData = response.data.results;

      // Convert JSON to CSV
      const csv = jsonToCsv(jsonData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "exported_data.csv");

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      toast.success("Data exported successfully!", {});
    } catch (error) {
      console.log("Error exporting data:", error);
      toast.error("No data found", {});
    }
  };

  const jsonToCsv = (jsonData) => {
    const csvRows = [];
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(","));

    for (const row of jsonData) {
      const values = headers.map((header) => {
        let value = row[header];

        if (header === "Exported Date") {
          value = new Date(value).toISOString().split("T")[0]; // Format to YYYY-MM-DD
        }

        const escaped = ("" + value).replace(/"/g, '\\"');
        return `"${escaped}"`; // Wrap values in quotes to handle commas
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  const handleResetClick = async () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Please export all data before resetting. This action will reset all data. This cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reset it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/reset`,
            {},
            {
              headers: {
                Authorization: `Bearer ${yourToken}`,
              },
            }
          );
          toast.success("Collection reset successfully!", {});
        } catch (error) {
          console.error("Error resetting collection:", error);
          toast.error("Failed to reset collection", {});
        }
      }
    });
  };

  return (
    <div className={Styles.exportdatahomecontainer}>
      <div className={Styles.exportlogocontainer}>
        <img src={headLogo} alt="Logo" />
      </div>
      <div className={Styles.exportdatamain}>
        <div>
          <button className={Styles.exportbtn} onClick={handleExportClick}>
            Export All
          </button>
        </div>
        <div>
          <button className={Styles.exportbtn} onClick={handleResetClick}>
            Reset All Boards
          </button>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default ExportData;
