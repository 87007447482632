import React, { useState, useEffect } from "react";
import Axios from "axios";
import Styles from "../../Styles/scoreboard.module.scss";
import Headlogo from "../../Assets/Leaderboard/headlogo.webp";

const Vrtennisscoreboard = () => {
  const [data, setData] = useState([]);

  const Api = `${process.env.REACT_APP_API_URL}/scoreboard/game/675288058fe7ab7a5504812f?top=1`;

  const getdata = async () => {
    try {
      let response = await Axios.get(Api);

      setData(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getdata();
    const pollInterval = setInterval(() => {
      getdata();
    }, 5000);

    return () => {
      clearInterval(pollInterval);
    };
  }, []);

  const fielddata = Array.from({ length: 10 }, (_, index) => {
    return (
      data[index] || {
        uniqueCode: `placeholder-${index}`, // Corrected string interpolation
        user: { name: " " },
        score: " ",
      }
    );
  });
  

  return (
    <div className={Styles.leadermaincontainer}>
      <div className={Styles.leaderfixed}>
        <div className={Styles.leaderimgmain}>
          <img src={Headlogo} alt="headimg" draggable="false"/>
        </div>
        <div className={Styles.leaderlogocontainer}>
          <h1>VR TENNIS</h1>
        </div>

        <div className={Styles.leadersubheadingcontainer}>
          <div>
            <p>NAME</p>
          </div>

          <div>
            <p>SCORE</p>
          </div>
        </div>

        <div className={Styles.mainscoreboardcontainer}>
        { fielddata.map((item, index) => (
            <div key={item.uniqueCode} className={Styles.mainscoreboardrow}>
              <div className={Styles.containerone}>{index + 1}</div>
              <div className={Styles.separator}></div>
              <div className={Styles.containertwo}>{item.user.name}</div>
              <div className={Styles.containerthree}>{item.score}</div>
            </div>
          ))}

          {/* {/* 
             List 10 */}
{/* 
          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 1</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 2</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 3</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 4</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 5</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 6</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 7</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 8</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 9</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>

          <div className={Styles.mainscoreboardrow}>
            <div className={Styles.containerone}> 10</div>

            <div className={Styles.containertwo}> Rekhil Alias Paulose</div>

            <div className={Styles.containerthree}>100</div>
          </div>  */}

          {/* List 10 ends */}
        </div>
      </div>
    </div>
  );
};

export default Vrtennisscoreboard;