import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function UnprotectedRoute() {
  const locationTo = "/exportdata";

  const adminToken = localStorage.getItem("adminToken");

  return !adminToken ? <Outlet /> : <Navigate to={locationTo} replace />;
}

export default UnprotectedRoute;
